<template>
  <v-dialog v-model="navigation" fullscreen hide-overlay>
    <template v-slot:activator="{ on, attrs }">
      <div
        class="navBtn"
        :class="{ active: navigation }"
        v-bind="attrs"
        v-on="on"
      >
        <span />
        <span />
        <span />
      </div>
    </template>
    <v-card class="gradient">
      <v-container
        fluid
        class="d-flex flex-column justify-space-between pa-10"
        style="width: 100% !important; height: 100vh"
      >
        <ul class="list--navigation">
          <menu-item v-for="(r, i) in routes" :key="i" :route="r" />
        </ul>
        <div class="nav--bottom">
          <v-btn text color="white"
            ><v-icon left color="white">fas fa-globe-europe</v-icon>United
            Kingom</v-btn
          >
          <social-links direction="horizontal" />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SocialLinks from "@/components/SocialLinks.vue";
import MenuItem from "./NavigationItem.vue";

export default {
  name: "Navigation",
  components: {
    SocialLinks,
    MenuItem,
  },
  data() {
    return {
      navigation: false,
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
};
</script>

<style lang="scss"></style>
