<template>
  <div class="header">
    <div
      v-if="!isYoutube"
      class="background-cover"
      :style="{ 'background-image': 'url(' + headerImage + ')' }"
      style="position: fixed; top: 0; left: 0; bottom: 0; right: 0; z-index: 0"
    />
    <div v-if="displayOverlay == true" class="header--overlay">
      <div class="top" />
      <div class="middle">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1021.17 906.03">
          <defs>
            <mask id="mask-path">
              <rect width="110%" height="110%" x="-5%" y="-5%" fill="white" />
              <path
                id="qcomms_path"
                data-name="qcomms_path"
                d="M619.74,715.13l11.33-6.84a276.11,276.11,0,0,0,60.06-52.57A318.58,318.58,0,0,0,736,590.53a275.08,275.08,0,0,0,28.21-81.44,287,287,0,0,0,3.64-87.63A315.25,315.25,0,0,0,746.48,339a284.2,284.2,0,0,0-40.82-72.88,428.24,428.24,0,0,0-41.68-44,241.06,241.06,0,0,0-40-30.56C605.2,181,586.18,171.4,566.52,162.85a269.16,269.16,0,0,0-75-18.17A299.12,299.12,0,0,0,384.63,153a364.92,364.92,0,0,0-80.79,32.48,276.08,276.08,0,0,0-38.47,25.86,87.65,87.65,0,0,0-16,14.75c-15.6,18-32.28,35.48-46.17,54.93A291.71,291.71,0,0,0,179,327.63a363.52,363.52,0,0,0-16.67,41.68,284.79,284.79,0,0,0-8.55,125,557.48,557.48,0,0,0,15.17,59,223.72,223.72,0,0,0,12.61,35.27,225.55,225.55,0,0,0,18.81,31.63A325,325,0,0,0,256,684.35a249.88,249.88,0,0,0,70.1,46.81A468.12,468.12,0,0,0,384,752.53a320,320,0,0,0,69.68,9.62c142.34,0,284.69,0,427.46,1.28a87.84,87.84,0,0,1,71,37.62,81.65,81.65,0,0,1,16.46,47,9,9,0,0,1-6.84,10.69H459.65c-21.37,0-44.24-4.7-66.26-6.41a305.37,305.37,0,0,1-89.55-25.65A666.67,666.67,0,0,1,235,787.37a305.85,305.85,0,0,1-59.85-47.66A317.9,317.9,0,0,1,123,677.08C112.55,660,101.65,644,91.18,626.43a391.91,391.91,0,0,1-16.25-40.61A409.74,409.74,0,0,1,53.56,484.73,439.89,439.89,0,0,1,56.12,396,474.76,474.76,0,0,1,87.76,285.74c5.55-12,11.75-23.51,18.38-34.84a311.58,311.58,0,0,1,51.94-71,717.3,717.3,0,0,1,60.27-53.43,331.54,331.54,0,0,1,76.09-44.46A381.13,381.13,0,0,1,412.2,50a435.67,435.67,0,0,1,119.06,3,388.4,388.4,0,0,1,62,17.95,281.66,281.66,0,0,1,54.93,21.37,608.23,608.23,0,0,1,82.07,57.5,352.34,352.34,0,0,1,50.87,57.07,572.81,572.81,0,0,1,37.19,56,217.62,217.62,0,0,1,15.18,34.2A384.68,384.68,0,0,1,861.9,410.56a347.45,347.45,0,0,1-2.35,100.89A519.28,519.28,0,0,1,841.17,588,487.21,487.21,0,0,1,812.1,650.8a236.38,236.38,0,0,1-18.6,32.92A197.91,197.91,0,0,1,767.86,716,33.19,33.19,0,0,1,748,719.84H622.73Z"
              />
              <text
                id="qcomms_text"
                width="50%"
                height="100%"
                x="50%"
                y="40%"
                text-anchor="middle"
              >
                <tspan
                  v-for="(item, id) in overlayCopy"
                  :key="id"
                  x="50%"
                  :y="50 - (overlayCopy.length - 1) * 3 + 5.5 * (id * 1) + '%'"
                  :dy="[overlayCopy.length > 1 ? '3rem' : '0']"
                  :font-size="item.size"
                >
                  {{ item.copy }}
                </tspan>
              </text>
            </mask>
          </defs>
          <rect
            width="110%"
            height="110%"
            x="-5%"
            y="-5%"
            fill="#000000"
            fill-opacity="0.7"
            mask="url(#mask-path)"
          />
        </svg>
      </div>
      <div class="bottom" />
    </div>

    <div v-else style="height: 100%">
      <component
        :is="displayVideo ? 'video-background' : 'div'"
        :src="videoSrc"
        :poster="headerImage"
        style="height: 100%"
        class="vid-container"
        objectFit="cover"
      >
        <div class="header--container">
          <div class="header--top">
            <div class="logo">
              <router-link to="/">
                <svg
                  id="logo"
                  xmlns="http://www.w3.org/2000/svg"
                  height="64px"
                  viewBox="0 0 1021.17 906.03"
                >
                  <path
                    id="logo-path"
                    data-name="logo"
                    fill="#ffffff"
                    d="M619.74,715.13l11.33-6.84a276.11,276.11,0,0,0,60.06-52.57A318.58,318.58,0,0,0,736,590.53a275.08,275.08,0,0,0,28.21-81.44,287,287,0,0,0,3.64-87.63A315.25,315.25,0,0,0,746.48,339a284.2,284.2,0,0,0-40.82-72.88,428.24,428.24,0,0,0-41.68-44,241.06,241.06,0,0,0-40-30.56C605.2,181,586.18,171.4,566.52,162.85a269.16,269.16,0,0,0-75-18.17A299.12,299.12,0,0,0,384.63,153a364.92,364.92,0,0,0-80.79,32.48,276.08,276.08,0,0,0-38.47,25.86,87.65,87.65,0,0,0-16,14.75c-15.6,18-32.28,35.48-46.17,54.93A291.71,291.71,0,0,0,179,327.63a363.52,363.52,0,0,0-16.67,41.68,284.79,284.79,0,0,0-8.55,125,557.48,557.48,0,0,0,15.17,59,223.72,223.72,0,0,0,12.61,35.27,225.55,225.55,0,0,0,18.81,31.63A325,325,0,0,0,256,684.35a249.88,249.88,0,0,0,70.1,46.81A468.12,468.12,0,0,0,384,752.53a320,320,0,0,0,69.68,9.62c142.34,0,284.69,0,427.46,1.28a87.84,87.84,0,0,1,71,37.62,81.65,81.65,0,0,1,16.46,47,9,9,0,0,1-6.84,10.69H459.65c-21.37,0-44.24-4.7-66.26-6.41a305.37,305.37,0,0,1-89.55-25.65A666.67,666.67,0,0,1,235,787.37a305.85,305.85,0,0,1-59.85-47.66A317.9,317.9,0,0,1,123,677.08C112.55,660,101.65,644,91.18,626.43a391.91,391.91,0,0,1-16.25-40.61A409.74,409.74,0,0,1,53.56,484.73,439.89,439.89,0,0,1,56.12,396,474.76,474.76,0,0,1,87.76,285.74c5.55-12,11.75-23.51,18.38-34.84a311.58,311.58,0,0,1,51.94-71,717.3,717.3,0,0,1,60.27-53.43,331.54,331.54,0,0,1,76.09-44.46A381.13,381.13,0,0,1,412.2,50a435.67,435.67,0,0,1,119.06,3,388.4,388.4,0,0,1,62,17.95,281.66,281.66,0,0,1,54.93,21.37,608.23,608.23,0,0,1,82.07,57.5,352.34,352.34,0,0,1,50.87,57.07,572.81,572.81,0,0,1,37.19,56,217.62,217.62,0,0,1,15.18,34.2A384.68,384.68,0,0,1,861.9,410.56a347.45,347.45,0,0,1-2.35,100.89A519.28,519.28,0,0,1,841.17,588,487.21,487.21,0,0,1,812.1,650.8a236.38,236.38,0,0,1-18.6,32.92A197.91,197.91,0,0,1,767.86,716,33.19,33.19,0,0,1,748,719.84H622.73Z"
                  />
                </svg>
              </router-link>
            </div>
            <navigation />
          </div>
          <div class="header--middle"></div>
          <div class="header--bottom">
            <div class="social">
              <social-links direction="vertical" />
            </div>
            <div class="scroll">
              <v-btn text color="white" v-scroll-to="'#start'"
                >Start Exploring
                <v-icon color="white" size="13" right
                  >fas fa-arrow-down</v-icon
                ></v-btn
              >
            </div>
            <div />
          </div>
        </div>
      </component>
    </div>
    
  </div>
</template>

<script>
import SocialLinks from "../../components/SocialLinks.vue";
import Navigation from "./Navigation.vue";

export default {
  name: "Header",
  components: {
    Navigation,
    SocialLinks,
  },
  props: {
    headerImage: String,
    displayOverlay: Boolean,
    overlayCopy: Array,
    displayVideo: Boolean,
    videoSrc: String,
    isYoutube: Boolean
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}
.vue-responsive-videobg {
  background: #7c008d;
}

</style>
